import React, { Component } from 'react';
import DirectoryList from './DirectoryList.js';
import SlideDatePicker from './SlideDatePicker.js';
import CloseIcon from '../images/window-close-solid.svg';
import CheckMark from '../images/checkmark.svg';

class ImgUploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: false,
			fileForm: {
				order: '',
				duration: '',
				description: '',
				start_date: '',
				stop_date: ''
			},
			schedule: {
				scheduledDays: [],
				startTime: '',
				stopTime: '',
				errors: {
					dayError: false,
					timeError: false
				},
				schedulingEnabled: false
			},
			durationDisplay: false,
			rules: {
				sizes: {
					'image/*': {
						size: 10,
						unit: 'mb'
					},
					'video/*': {
						size: 400,
						unit: 'mb'
					}
				},
				types: [
					'image/png',
					'image/jpg',
					'image/jpeg',
					'video/mp4',
					'video/mov',
					'video/quicktime',
					'video/webm'
				]
			},
			systems: [],
			fileNameText: ''
		};

		this.checkFormErrors = this.checkFormErrors.bind(this);
		this.clearFile = this.clearFile.bind(this);
		this.clearSchedule = this.clearSchedule.bind(this);
		this.durationEnabled = this.durationEnabled.bind(this);
		this.handleDateUpdate = this.handleDateUpdate.bind(this);
		this.handleFormUpdate = this.handleFormUpdate.bind(this);
		this.handleSystemUpdate = this.handleSystemUpdate.bind(this);
		this.handleUploadImage = this.handleUploadImage.bind(this);
		this.submitEnabled = this.submitEnabled.bind(this);
		this.toggleCheckBox = this.toggleCheckBox.bind(this);
		this.updateFieldValue = this.updateFieldValue.bind(this);
		this.updateSchedule = this.updateSchedule.bind(this);
	}

	clearFile() {
		let elm = document.getElementById('uploadInput');
		elm.value = '';
		this.setState({
			file: false,
			fileForm: {
				order: '',
				duration: '',
				description: '',
				start_date: '',
				stop_date: ''
			},
			systems: []
		});
	}

	handleUploadImage(evt) {
		evt.preventDefault();
		let elm = document.getElementById('uploadInput');
		if (elm.files && elm.files.length > 0) {
			let f = elm.files[0];
			if (this.state.rules.types.indexOf(f.type.trim()) === -1) {
				elm.value = '';
				alert('Please use a valid file name. The file name cannot contain: a space, \\, /,:, ?,\",<, , >, or |.');
				return;
			}

			if (!this.checkString(f.name)) {
				elm.value = '';
				let successMessage = document.getElementById('successMessage');
				successMessage.style.display = 'none';
				this.setState({ file: false });
				alert('Please use a valid file name.');
				return;
			}

			let fileSize = f.size;
			let goodSize = true;
			Object.keys(this.state.rules.sizes).forEach((key) => {
				let typeSegments = f.type.split('/');
				let sizeSegments = key.split('/');
				if (typeSegments[0] !== sizeSegments[0]) {
					return;
				}
				if (typeSegments[1] === sizeSegments[1] || sizeSegments[1] === '*') {
					let ruleUnit = this.state.rules.sizes[key].unit;
					let ruleSize = Number(this.state.rules.sizes[key].size + 1);
					if (ruleUnit === 'mb') {
						fileSize = fileSize / 1024 / 1024;
					}
					if (ruleUnit === 'kb') {
						fileSize = fileSize / 1024;
					}

					if (fileSize > ruleSize) {
						elm.value = '';
						alert(
							'file size: ' +
							Math.floor(fileSize) +
							'(' +
							ruleUnit +
							') is too large for file type.'
						);
						goodSize = false;
					}
				}
			});
			let fileArray = f.type.split('/');
			let fileType = fileArray[0];
			let durationEnabled;
			if (fileType === 'video') {
				durationEnabled = false;
			} else {
				durationEnabled = true;
			}
			if (goodSize) {
				this.setState({
					file: f,
					durationDisplay: durationEnabled,
					fileNameText: elm.files[0].name
				});
			}
			let successMessage = document.getElementById('successMessage');
			if (goodSize) {
				successMessage.style.display = 'flex';
			} else {
				successMessage.style.display = 'none';
			}
		}
	}

	handleFormUpdate(evt, formKey, numLimit) {
		console.log(evt.keyCode);
		let nextForm = Object.assign({}, this.state.fileForm);
		if (formKey === 'description') {
			nextForm[formKey] = evt.target.value;
			this.setState({ fileForm: nextForm });
		} else if (!numLimit || evt.target.value.length <= numLimit) {
			let char = parseInt(evt.target.value);
			if ((!isNaN(char) && char < 0) || isNaN(char)) {
				char = '';
			} else {
				char = char.toString();
			}
			nextForm[formKey] = char;
			// nextForm[formKey] = evt.target.value;
			this.setState({ fileForm: nextForm });
		}
	}

	handleDateUpdate(_index, valueKey, value) {
		let nextForm = Object.assign({}, this.state.fileForm);
		nextForm[valueKey] = value;
		this.setState({ fileForm: nextForm });
	}

	handleSystemUpdate(sys) {
		let nextSystems = this.state.systems;
		let targetIndex = nextSystems.indexOf(sys);
		if (targetIndex !== -1) {
			nextSystems.splice(targetIndex, 1);
			return this.setState({ systems: nextSystems });
		}

		nextSystems.push(sys);
		return this.setState({ systems: nextSystems });
	}

	toggleCheckBox(targetSys) {
		let systems = this.state.systems;
		let targetIndex = this.state.systems.indexOf(targetSys);
		console.log(targetIndex);
		console.log(this.state.systems);
		if (targetIndex !== -1) {
			systems.splice(targetIndex, 1);
			return this.setState({ systems: systems });
		}
		systems.push(targetSys);
		return this.setState({ systems: systems });
	}

	updateFieldValue(field, value) {
		let nextForm = Object.assign({}, this.state.fileForm);
		nextForm[field] = value;
		this.setState({ fileForm: nextForm });
	}

	updateSchedule(field, value) {
		let newSchedule = Object.assign({}, this.state.schedule);
		newSchedule[field] = value;
		this.setState({ schedule: newSchedule });
	}

	clearSchedule() {
		let newSchedule = Object.assign({}, this.state.schedule);
		newSchedule = {
			scheduledDays: [],
			startTime: '',
			stopTime: '',
			errors: {
				dayError: false,
				timeError: false
			},
			schedulingEnabled: false
		};
		this.setState({ schedule: newSchedule });
	}

	confirmUpload() {
		let confirmWindow = window.confirm('You have pending changes, would you like to continue without saving?');
		if (confirmWindow == true) {
			this.props.closeModal();
		} else {
		}
	}

	confirmUploadEnabled() {
		if (this.state.file) {
			return (
				<i className="fa fa-window-close" onClick={
					() => {
						return this.confirmUpload();
					}}></i>
			)
		}
		return (
			<i className="fa fa-window-close" onClick={
				() => {
					return this.props.closeModal();
				}}></i>
		)
	}

	submitEnabled() {
		const buttonText = 'Submit';
		if (this.state.file) {
			return (
				<div className="modalButtonContainer">
					<button className="btn btn-secondary" onClick={() => {
						this.confirmUpload()
					}} >Cancel</button>
					<button
						id="submitButton"
						className="btn"
						onClick={() => {
							if (!this.checkFormErrors()) {
								this.props.addObject(
									this.state.file,
									this.state.fileForm,
									this.state.systems,
									this.props.activeSlideshow,
									this.props.callback
								);
								this.clearFile();
								this.props.closeModal();
							}
						}}
					>
						{buttonText}
					</button>
				</div>
			);
		}
		return (
			<div className="modalButtonContainer">
				<button className="btn btn-secondary" onClick={
					() => {
						return this.props.closeModal();
					}}>Cancel</button>
				<button className="btn btnDisabled">{buttonText}</button>
			</div>
		)
	}

	durationEnabled(index, formKey, displayKey) {
		if (this.state.durationDisplay === true) {
			return <div className="formField" key={`form-input-${index}`}><label>{displayKey} (secs)</label> <input id={formKey} value={this.state.fileForm[formKey]} onChange={(evt) => {
				this.handleFormUpdate(evt, formKey);
			}} type="number" /> </div>;
		}
		return <div className="formField disabled" key={`form-input-${index}`}><label>{displayKey} (secs)</label> <input id={formKey} value={this.state.fileForm[formKey]} type="number" disabled={true} /> </div>;
	}

	checkFormErrors() {
		const schedule = this.state.schedule;
		let showError = false;
		let dayError = false;
		let timeError = false;
		if (schedule.schedulingEnabled) {
			if (
				!schedule.scheduledDays ||
				(schedule.scheduledDays && schedule.scheduledDays.length === 0)
			) {
				dayError = true;
				showError = true;
			}
			if (
				!schedule.startTime ||
				!schedule.stopTime ||
				(schedule.startTime && schedule.startTime.length === 0) ||
				(schedule.stopTime && schedule.stopTime.length === 0)
			) {
				timeError = true;
				showError = true;
			}
			const errorObj = {
				dayError: dayError,
				timeError: timeError
			};
			this.updateSchedule('errors', errorObj);
		}
		return showError;
	}

	checkString(str) {
		let reg = /^[a-zA-Z0-9@\!\-\_\.\*\'\(\)]*$/g
		if (!reg.test(str)) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		let dirProps = {
			directoryList: this.props.directoryList,
			selectedSystems: this.state.systems,
			toggleSystemSelect: this.handleSystemUpdate,
			toggleCheckBox: this.toggleCheckBox
		};

		return (
			<div className="modal-container">
				<div className="modal-backdrop" />
				<div className="modal-body">
					<div className="modal-header">
						<h1>Add Slide</h1>
						{this.confirmUploadEnabled()}
					</div>
					<div className="modal-content">
						<div className="modal-column1">
							<h2>
								Upload image
							</h2>
							<p className="subtitle" htmlFor="fileUpload">
								<span><strong>Image format:</strong>	&nbsp;.JPG or .PNG</span><br />
								<span><strong>Max image size:</strong>	&nbsp;10mb</span><br />
								<span><strong>Movie format: </strong>	&nbsp;.MP4 / .MOV as H.264, or .WebM.</span><br />
								<span><strong>Max video size:</strong>	&nbsp;100mb</span>
							</p>
							<div className="btn-upload__container">
								<label className="btn-upload">
									<input onChange={(evt) => {
										this.handleUploadImage(evt);
									}} id="uploadInput" type="file" />
									<button className="btn">Upload File</button>
								</label>
								<div id="successMessage">
									<div className="successMessage-title">
										<i class="fa fa-check"></i>
										<h6>{this.state.fileNameText}</h6>
									</div>
								</div>
							</div>

							{this.state.error ? <p>Error Encountered: {this.state.error}</p> : ''}
							<div>
								{Object.keys(this.state.fileForm).map((formKey, i) => {
									let displayKey = formKey
										.split('_')
										.map((k) => {
											return k.charAt(0).toUpperCase() + k.substring(1);
										})
										.join(' ');
									if (formKey === 'start_date' || formKey === 'stop_date') {
										return (
											<div key={`form-input-${i}`} className="formField">
												<label>{displayKey}</label>
												<SlideDatePicker
													displayDate={this.state.fileForm[formKey]}
													changeFormValue={this.handleDateUpdate}
													index={0}
													valueKey={formKey}
													updateFieldValue={this.updateFieldValue}
												/>
											</div>
										);
									} else if (formKey === 'order') {
										return (<div className="formField" key={`form-input-${i}`}><label>Position</label> <input id={formKey} value={this.state.fileForm[formKey]} onChange={(evt) => {
											this.handleFormUpdate(evt, formKey, 3);
										}} type="text" /> </div>);
									} else if (formKey === 'duration') {
										return this.durationEnabled(i, formKey, displayKey);
									} else if (formKey === 'description') {
										return (<div className="formField" key={`form-input-${i}`}><label>Description</label> <input id={formKey} value={this.state.fileForm[formKey]} onChange={(evt) => {
											this.handleFormUpdate(evt, formKey);
										}} type="text" /> </div>);
									}
									return false;
								})}
							</div>
						</div>
						<div className="modal-column2">
							<h2>
								Slide Locations
							</h2>
							{/* <div className="subtitle">
								Check where this slide should display.
							</div> */}
							{DirectoryList(dirProps)}
						</div>
					</div>
					<div className="modal-footer">
						{this.submitEnabled()}
					</div>
				</div>
			</div>
		);
	}
}

export default ImgUploader;

// } else if (formKey !== "duration") {
// 	return (
// 		<div className="formField" key={`form-input-${i}`}>
// 			<label>{displayKey}</label>{" "}
// 			<input
// 				id={formKey}
// 				value={this.state.fileForm[formKey]}
// 				onChange={evt => {
// 					this.handleFormUpdate(evt, formKey);
// 				}}
// 				type="text"
// 			/>{" "}
// 		</div>
// 	);
// } else if (formKey === "duration") {
