import React from 'react';

class SlideshowList extends React.Component {
    state = {
        ...this.props,
        items: this.props.items || [],
        showItems: false,
        selectedItem: this.props.items && this.props.items[0]
    }

    componentDidMount() {
        this.props.toggleActiveSlideShow(this.state.selectedItem.id);
    }

    dropDown = () => {
        this.setState(prevState => ({
            showItems: !prevState.showItems,
        }))
    }

    selectItem = (item) => this.setState({
        selectedItem: item,
        showItems: false,
    })

    hoverOffDropdown = () => {
        this.setState(prevState => ({
            showItems: false,
        }))
    }

    render() {
        return (
            <div id="dropdownID" className="select-box--box" onMouseLeave={this.hoverOffDropdown}>
                <div className="select-box--container">
                    <div className="select-box--selected-item">
                        {this.state.selectedItem.value}
                    </div>
                    <div
                        className="select-box--arrow"
                        onClick={this.dropDown}
                        
                    >
                        <span className={`${this.state.showItems ? 'select-box--arrow-up' : 'select-box--arrow-down'}`}></span>
                    </div>
                    <div
                        style={{ display: this.state.showItems ? 'block' : 'none' }}
                        className="select-box--items"
                        
                    >
                        {
                            this.state.items.map(item =>
                                <div
                                    key={item.id}
                                    onClick={() => {
                                        this.selectItem(item);
                                        this.props.toggleActiveSlideShow(item.id);
                                    }}
                                    className={this.state.selectedItem === item ? 'selected' : ''}
                                >
                                    {item.value}
                                </div>)
                        }
                    </div>
                    <input
                        type="hidden"
                        value={this.state.selectedItem.id}
                        name={this.state.name}
                    />
                </div>
            </div>
        )
    }

}

export default SlideshowList;