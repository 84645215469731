import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

class SlideDatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayDate: (this.props.displayDate) ? moment(this.props.displayDate, 'YYYY-MM-DD_HH:mm') : '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({displayDate: date});
		this.props.updateFieldValue(this.props.valueKey, (date) ? date.toString() : '');
	}

	render() {
		return (
			<DatePicker
				selected={this.state.displayDate || null}
				onChange={this.handleChange}
				showTimeSelect
				timeIntervals={15}
				timeCaption="time"
				dateFormat="YYYY-MM-DD_HH:mm"
			/>
		);
	}
}


export default SlideDatePicker;
