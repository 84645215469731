import React, { Fragment } from "react";
import SlideshowList from "./SlideshowList";

const DirectoryList = ({ ...props }) => {
  const selectedSystems = props.selectedSystems ? props.selectedSystems : [];
  const directories = Object.keys(props.directoryList).map(dirID => {
    let currentDir = props.directoryList[dirID];

    return (
      <li>
        <div className="directoryListItem" key={currentDir.key}>
          <label>
            <input
              type="checkbox"
              checked={selectedSystems.indexOf(currentDir.directory) !== -1}
              onChange={() => {
                props.toggleCheckBox(currentDir.directory);
              }}
            />
            <div className="checkBox" />
            <div className="directory-name">{currentDir.directory} - {currentDir.displayName}</div>
          </label>
        </div>
      </li>
    );
  });

  let selectAll = () => {
    console.log(props.directoryList);
    console.log(selectedSystems);
    Object.keys(props.directoryList).forEach(directory => {
      if (selectedSystems.indexOf(directory) === -1) {
        props.toggleCheckBox(directory);
        console.log(directory);
      }
    });
  };

  let selectNone = () => {
    Object.keys(props.directoryList).forEach(directory => {
      if (selectedSystems.indexOf(directory) !== -1) {
        props.toggleCheckBox(directory);
      }
    });
  };

  let searchDirectories = () => {

    let input, filter, ul, li, label, i, txtValue;
    input = document.getElementById('searchDirectories');
    filter = input.value.toUpperCase();
    ul = document.getElementById("directoryList");
    li = ul.getElementsByTagName('li');

    for (i = 0; i < li.length; i++) {
      label = li[i].getElementsByTagName("label")[0];
      txtValue = label.textContent || label.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "block";
      } else {
        li[i].style.display = "none";
      }
    }

    if (input.value !== '') {
      document.getElementById('selectAllNone').classList.add('disabled');
    }

    if (input.value == '') {
      document.getElementById('selectAllNone').classList.remove('disabled');
    }

  }

  // let successMessageBlock = document.getElementById('successMessage');

  // if (successMessageBlock.style.display === 'block') {
  //   document.getElementById('directoryListContainer').classList.add('make-taller');
  // } else {
  //   document.getElementById('directoryListContainer').classList.add('');
  // }

  return (
    <Fragment>
      <div class="search">
        <span class="fa fa-search"></span>
        <input type="text" id="searchDirectories" onKeyUp={searchDirectories} placeHolder="Search directories..." />
        <div  id="selectAllNone" className="select--all-none">
          <span>Select:</span>
          <a
            id="selectAllButton"
            onClick={() => {
              selectAll();
            }}
          >
            All
        </a>
          |
        <a
            id="selectNoneButton"
            onClick={() => {
              selectNone();
            }}
          >
            None
        </a>
        </div>
      </div>

      <div id="directoryListContainer" className="directoryListContainer">
        <ul id="directoryList" className="directoryList">{directories}</ul>
      </div>
    </Fragment>
  );
};

export default DirectoryList;
