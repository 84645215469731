import React, { Component } from 'react';
import DirectoryList from './DirectoryList.js';
import EditSlideField from './EditSlideField.js';
import SlideDatePicker from './SlideDatePicker.js';
import CloseIcon from '../images/window-close-solid.svg';

class SlideForm extends Component {
	constructor(props) {
		super(props);

		let propSys = [];
		if (this.props.systems && this.props.systems.length && this.props.systems.length > 0) {
			this.props.systems.forEach((sys) => {
				propSys.push(sys);
			});
		}

		this.state = {
			systems: propSys,
			order: this.props.order,
			duration: this.props.duration,
			description: this.props.description,
			start_date: this.props.start_date,
			stop_date: this.props.stop_date
		};

		this.dListProps = {
			directoryList: this.props.directoryList,
			selectedSystems: this.state.systems,
			toggleSystemSelect: (sys) => {
				props.toggleSystemSelect(this.props.index, sys);
			},
			toggleCheckBox: this.toggleCheckBox
		};
		this.fileArray = this.props.assetURL.split('/');
		this.fileName = this.fileArray[this.fileArray.length - 1];
		this.objectKey = `${this.fileArray[this.fileArray.length - 2]}/${this.fileArray[this.fileArray.length - 1]}`;
		this.fileTypeArray = this.props.kind.split('/');
		this.fileType = this.fileTypeArray[0];
	}

	displayDuration = () => {
		if (this.fileType == 'video') {
			return EditSlideField({
				label: 'Duration (secs)',
				value: this.state.duration,
				valueKey: 'duration',
				changeFormValue: this.props.changeFormValue,
				index: this.props.index,
				updateFieldValue: this.updateFieldValue,
				className: 'disabled'
			});
		} else {
			return EditSlideField({
				label: 'Duration (secs)',
				value: this.state.duration,
				valueKey: 'duration',
				changeFormValue: this.props.changeFormValue,
				index: this.props.index,
				updateFieldValue: this.updateFieldValue,
				className: ''
			});
		}
	}

	toggleCheckBox = (targetSys) => {
		let systems = this.state.systems;
		let targetIndex = this.state.systems.indexOf(targetSys);
		if (targetIndex !== -1) {
			systems.splice(targetIndex, 1);
			return this.setState({ systems: systems });
		}
		systems.push(targetSys);
		return this.setState({ systems: systems });
	}

	updateFieldValue = (field, value) => {
		if (field === 'start_date' || field === 'stop_date') {
			return (this.setState({ [field]: value.toString() || '' }));
		}

		if (field === 'description') {
			return (this.setState({ [field]: value }));
		}

		// if position...
		let char = parseInt(value);
		if ((!isNaN(char) && char < 0) || isNaN(char)) {
			char = '';
		} else {
			char = char.toString();
		}
		return (this.setState({ [field]: char }));
	}

	render() {
		let fileForm = {
			order: this.state.order,
			duration: this.state.duration,
			description: this.state.description,
			start_date: this.state.start_date,
			stop_date: this.state.stop_date,
			systems: this.state.systems
		};
		let finalKey = ``;

		if (this.fileArray.length >= 2) {
			finalKey = [this.fileArray[this.fileArray.length - 2], this.fileArray[this.fileArray.length - 1]].join('/');
		}

		let confirmDelete = () => {
			let confirmWindow = window.confirm('Are you sure you want to delete this slide?');
			if (confirmWindow == true) {
				this.props.deleteObject(finalKey, this.props.runFullSetup);
				document.body.classList.remove('no-scroll');
			} else {
			}
		}

		return (
			<div className="modal-container">
				<div className="modal-backdrop"></div>
				<div className="modal-body">
					<div className="modal-header">
						<h1>Edit Slide</h1>
						<i className="fa fa-window-close" onClick={
							() => {
								return this.props.closeModal();
							}}></i>
					</div>
					<div className="modal-content">
						<div className="modal-column1">
							<h2>
								Edit Display Settings
							</h2>
							<div className="slideImgCont">
								<div className="blue-triangle"></div>
								<div className="thumb-count">{this.state.order}</div>
								<div onClick={
									() => {
										console.log('why is this here?');
									}
								}>{this.props.imgElm}</div>
							</div>
							{EditSlideField({
								label: 'Description',
								value: this.state.description,
								valueKey: 'description',
								changeFormValue: this.props.changeFormValue,
								index: this.props.index,
								updateFieldValue: this.updateFieldValue,
							})}
							{EditSlideField({
								label: 'Filename',
								value: this.fileName,
								readOnly: true
							})}
							{EditSlideField({
								label: 'Position',
								value: this.state.order,
								valueKey: 'order',
								changeFormValue: this.props.changeFormValue,
								index: this.props.index,
								updateFieldValue: this.updateFieldValue,
								maxLength: 3
							})}
							{this.displayDuration()}

							<div className="formField">
								<label>Start Date</label>
								<SlideDatePicker index={this.props.index} valueKey="start_date" title={'Start Date'} changeFormValue={this.props.changeFormValue} displayDate={this.state.start_date} updateFieldValue={this.updateFieldValue} />
							</div>
							<div className="formField">
								<label>Stop Date</label>
								<SlideDatePicker index={this.props.index} valueKey="stop_date" title={'Stop Date'} changeFormValue={this.props.changeFormValue} displayDate={this.state.stop_date} updateFieldValue={this.updateFieldValue} />
							</div>

						</div>
						<div className="modal-column2">
							<h2>
								Slide Locations
							</h2>
							{/* <div className="subtitle">Check where this slide should display.</div> */}
							{DirectoryList(this.dListProps)}
						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-secondary" onClick={() => {
							confirmDelete()
						}} >Delete Slide</button>
						<button className="btn" onClick={
							() => {
								return this.props.updateObject(this.objectKey, { type: this.props.kind }, fileForm, this.props.runFullSetup);
							}
						}>Submit</button>
					</div>
				</div>
			</div>
		);
	}

}

export default SlideForm;
