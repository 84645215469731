import React, { Component, Fragment } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import moment from 'moment';

class DirectoryView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: null
		};
	}

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	sortedGallery = () => {
		let galleryData = this.props.galleryData;
		let activeGalleryData = [];
		galleryData.forEach((entry) => {
			if (entry.slideshow === undefined) {
				entry.slideshow = [1];
			}

			entry.slideshow.forEach((value) => {
				if (value === this.props.activeSlideshow) {
					activeGalleryData.push(entry);
				}
			});
		});

		let sortedList = activeGalleryData.sort(function (a, b) {
			let propA = parseInt(a.order);
			let propB = parseInt(b.order);
			if (a.description == '' || b.description == '' || !a.description || !b.description || a.description == undefined || b.description == undefined) {
				console.log('Empty Data so sort -1');
				return -1;
			}
			// if same order, then use description
			if (propA == propB) {
				if (a.description.trim().toLowerCase() < b.description.trim().toLowerCase()) {
					console.log('a.description < b.description so sort -1');
					return -1;
				} else if (a.description.trim().toLowerCase() > b.description.trim().toLowerCase()) {
					console.log('a.description > b.description so sort 1');
					return 1;
				}
				// if same order & same description, then use filename
				let a_NameArray = a.assetURL.split('/');
				let b_NameArray = b.assetURL.split('/');
				let a_SlideName = a_NameArray[(a_NameArray.length - 1)];
				let b_SlideName = b_NameArray[(b_NameArray.length - 1)];
				if (a_SlideName < b_SlideName) {
					return -1;
				} else if (a_SlideName > b_SlideName) {
					return 1;
				}
				return 0;


			} else if (propA < propB) {
				console.log('propAA < propB so sort -1');
				return -1;
			} else if (propA > propB) {
				console.log('propAA > propB so sort 1');
				return 1;
			}
			console.log('else return 0');
			return 0;

		});
		return sortedList;

	};

	searchDirectories = () => {

		let input, filter, ul, li, span, i, txtValue;
		input = document.getElementById('searchDirectories');
		filter = input.value.toUpperCase();
		ul = document.getElementById("directoriesAccordion");
		li = ul.getElementsByTagName('li');

		for (i = 0; i < li.length; i++) {
			span = li[i].getElementsByTagName("span")[0];
			txtValue = span.textContent || span.innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				li[i].style.display = "block";
			} else {
				li[i].style.display = "none";
			}
		}

	}

	render() {
		const { activeIndex } = this.state;

		const directories = Object.keys(this.props.directoryList).map(
			(dirID, i) => {
				let currentDir = this.props.directoryList[dirID];
				let associatedSystems = [];

				if (
					this.props.galleryData.length <= 0 ||
					Object.keys(this.props.directoryList).length <= 0
				) {
					return '';
				}

				this.sortedGallery().forEach((entry, j) => {
					if (!entry.systems) {
						entry.systems = [];
					}
					if (entry.systems.indexOf(currentDir.directory) !== -1) {
						let displayDateRange = ``;
						let convertStartDate = moment(entry.start_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD HH:mm');
						let convertStopDate = moment(entry.stop_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD HH:mm');
						let formatStartDate = ``;
						let formatStopDate = ``;
						let entryArray = entry.assetURL.split('/');
						let entryName = entryArray[entryArray.length - 1];
						let entryDuration = ``;
						let entryTypeArray = entry.kind.split('/');
						let entryType = entryTypeArray[0];

						if (entry.start_date && entry.start_date.length > 0) {
							formatStartDate = `${convertStartDate}`;
						}
						if (entry.stop_date && entry.stop_date.length > 0) {
							formatStopDate = `${convertStopDate}`;
						}
						if (
							entry.duration &&
							entry.duration.length > 0 &&
							entryType !== 'video'
						) {
							entryDuration = entry.duration + 's';
						} else if (entryType === 'video') {
							entryDuration = 'Video Length';
						}
						if (formatStartDate.length > 0 && formatStopDate.length > 0) {
							displayDateRange = `${formatStartDate} - ${formatStopDate}`;
						} else if (
							formatStartDate.length > 0 &&
							formatStopDate.length === 0
						) {
							displayDateRange = `Starting ${formatStartDate}`;
						} else if (
							formatStartDate.length === 0 &&
							formatStopDate.length > 0
						) {
							displayDateRange = `Ending ${formatStopDate}`;
						} else if (
							formatStartDate.length === 0 &&
							formatStopDate.length === 0
						) {
							displayDateRange = `Not Set`;
						}

						let entryElm = (
							<div className="s3Entry" key={`gallery-entry-${j}`} onClick={() => {
								this.props.toggleActiveItem(j);
							}}>
								<div className="slideImgCont">
									<div className="blue-triangle"></div>
									<div className="thumb-count">{entry.order}</div>
									<div onClick={
										() => {
											console.log('why is this here?');
										}
									}>
									</div>
								</div>
								<div
									className="slide-image">
									{entry.imgElm}
								</div>
								<div className="slideshow-text"><strong>Description:</strong> {entry.description}</div>
								<div className="slideshow-text"><strong>Filename:</strong> {entryName}</div>
								<div className="slideshow-text"><strong>Position:</strong> {entry.order}</div>
								<div className="slideshow-text"><strong>Duration:</strong> {entryDuration}</div>
								<div className="slideshow-text">
									<strong>Date Range:</strong> {displayDateRange}
								</div>
							</div>
						);

						associatedSystems.push(entryElm);
					}
				});

				return (
					<Fragment key={`directory-view-${i}`}>
						<li>
							<Accordion.Title
								active={activeIndex === i}
								index={i}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<span>{currentDir.directory} - {currentDir.displayName}</span>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === i}>
								<div className="accordionContent">{associatedSystems}</div>
							</Accordion.Content>
						</li>
					</Fragment>
				);
			}
		);

		return (
			<ul id="directoriesAccordion" className="accordionContainer">
				<Accordion fluid styled exclusive={false}>
					<div class="search">
						<span class="fa fa-search"></span>
						<input type="text" id="searchDirectories" onKeyUp={this.searchDirectories} placeHolder="Search directories..." />
					</div>
					{directories}
				</Accordion>
			</ul>
		);
	}
}

export default DirectoryView;
