import React from 'react';
import SlideshowList from "./SlideshowList";

const NavBar = ({...props}) => {
	let headerRestrictions = <div className="header-restrictions subtitle">
		<div className="header-restrictions-mobile">
            <span><strong>Image format:</strong>&nbsp;.JPG or .PNG</span>
            <span><strong>Max image size:</strong>&nbsp;10mb</span>
            <span><strong>Movie format: </strong>&nbsp;.MP4 / .MOV as H.264, or .WebM.</span>
            <span><strong>Max video size:</strong>&nbsp;100mb</span>
        </div>
        <div className="header-restrictions-desktop">
            <span><strong>Image format:</strong>&nbsp;.JPG or .PNG <strong>&nbsp;Max image size:</strong>&nbsp;10mb</span>
            <span><strong>Movie format: </strong>&nbsp;.MP4 / .MOV as H.264, or .WebM. <strong>&nbsp;Max video size:</strong>&nbsp;100mb</span>
        </div>
	</div>;
	let activeButton = (props.directoryView) ?
		(<button className="btn btn-secondary" onClick={props.viewGallery}>Return to Slide View</button>) :
		'';
	let directoryButton = !props.directoryView ?
		(<button className="btn btn-secondary" onClick={props.viewDirectory}>View By Directory</button>) :
		'';
	let uploadButton = <button className="btn btn-secondary" onClick={props.showUploadForm}>Add Slide</button>;
	return (
		<div className="nav-container">
			{headerRestrictions}
			<div className="header-buttons">
			<SlideshowList
				name="slideshowSelect"
				items={[
					{ value: 'Slideshow 1', id: 1 },
					{ value: 'Slideshow 2', id: 2 },
					{ value: 'Slideshow 3', id: 3 },
				]}
				toggleActiveSlideShow = {props.toggleActiveSlideShow}
			/>
				{activeButton}{' '}{directoryButton}{' '}{uploadButton}
			</div>
		</div>
	);
};

export default NavBar;
