import React from 'react';
import moment from 'moment';

const GalleryList = ({ ...props }) => {
	if (!props.list || props.list.length === 0) {
		return (<div><p>No Images Loaded</p></div>);
		// return (<div/>);
	}
	let list = [];

	let sortedList = props.list.sort(function (a, b) {
		let propA = parseInt(a.order);
		let propB = parseInt(b.order);
		if (a.description == '' || b.description == '' || !a.description || !b.description || a.description == undefined || b.description == undefined) {
			console.log('Empty Data so sort -1');
			return -1;
		}
		// if same order, then use description
		if (propA == propB) {
			if (a.description.trim().toLowerCase() < b.description.trim().toLowerCase()) {
				console.log('a.description < b.description so sort -1');
				return -1;
			} else if (a.description.trim().toLowerCase() > b.description.trim().toLowerCase()) {
				console.log('a.description > b.description so sort 1');
				return 1;
			}
			// if same order & same description, then use filename
			let a_NameArray = a.assetURL.split('/');
			let b_NameArray = b.assetURL.split('/');
			let a_SlideName = a_NameArray[(a_NameArray.length - 1)];
			let b_SlideName = b_NameArray[(b_NameArray.length - 1)];
			if (a_SlideName < b_SlideName) {
				return -1;
			} else if (a_SlideName > b_SlideName) {
				return 1;
			}
			return 0;


		} else if (propA < propB) {
			console.log('propAA < propB so sort -1');
			return -1;
		} else if (propA > propB) {
			console.log('propAA > propB so sort 1');
			return 1;
		}
		console.log('else return 0');
		return 0;

	});

	sortedList.forEach((entry, i) => {
		let displayDateRange = ``;
		let convertStartDate = moment(entry.start_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD HH:mm');
		let convertStopDate = moment(entry.stop_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD HH:mm');
		let description = entry.description;
		let formatStartDate = ``;
		let formatStopDate = ``;
		let entryArray = entry.assetURL.split('/');
		let entryName = entryArray[(entryArray.length - 1)];
		let entryDuration = ``;
		let entryTypeArray = [];
		let entryType = '';
		if (entry.kind) {
			entryTypeArray = entry.kind.split('/');
			entryType = entryTypeArray[0];
		}

		if (entry.start_date && entry.start_date.length > 0) {
			formatStartDate = `${convertStartDate}`;
		}

		if (entry.stop_date && entry.stop_date.length > 0) {
			formatStopDate = `${convertStopDate}`;
		}

		if (entry.duration && entry.duration.length > 0 && entryType !== 'video') {
			entryDuration = entry.duration + 's';
		} else if (entryType === 'video') {
			entryDuration = 'Video Length';
		}

		if (formatStartDate.length > 0 && formatStopDate.length > 0) {
			displayDateRange = `${formatStartDate} - ${formatStopDate}`;
		} else if (formatStartDate.length > 0 && formatStopDate.length === 0) {
			displayDateRange = `Starting ${formatStartDate}`;
		} else if (formatStartDate.length === 0 && formatStopDate.length > 0) {
			displayDateRange = `Ending ${formatStopDate}`;
		} else if (formatStartDate.length === 0 && formatStopDate.length === 0) {
			displayDateRange = `Not Set`;
		}

		if (entry.imgElm) {
			let elm = (
				<div key={`s3-img-${i}`} className="s3Entry" onClick={() => {
					props.toggleActiveItem(i);
				}}>
					<div className="slideImgCont">
						<div className="blue-triangle"></div>
						<div className="thumb-count">{entry.order}</div>
						<div onClick={
							() => {
								console.log('why is this here?');
							}
						}>
						</div>
					</div>

					<div
						className="slide-image">
						{entry.imgElm}
					</div>

					<div className="slideshow-text"><strong>Description:</strong> {entry.description}</div>
					<div className="slideshow-text"><strong>Filename:</strong> {entryName}</div>
					<div className="slideshow-text"><strong>Position:</strong> {entry.order}</div>
					<div className="slideshow-text"><strong>Duration:</strong> {entryDuration}</div>
					<div className="slideshow-text">
						<strong>Date Range:</strong> {displayDateRange}
					</div>
				</div>
			);
			list.push(elm);
		}
	});

	return (<div className="galleryList">{list}</div>);
};

export default GalleryList;
