import React from 'react';

const EditSlideField = ({...props}) => {
	let styles = {};
	let inputLength;
	if (props.readOnly) {
		styles = {background: '#d3d3d3'};
	}
	if (props.maxLength) {
		inputLength = props.maxLength;	
	}
	return (
		<div className={"formField " + props.className}>
			<label className={props.className}><span>{props.label}</span></label>
			{' '}
			<input className={props.className} style={styles} readOnly={props.readOnly} type="text" maxLength={inputLength} value={props.value} onChange={(e) => {
				props.updateFieldValue(props.valueKey, e.target.value);
			}}/>
		</div>
	);
};

export default EditSlideField;
