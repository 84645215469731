import * as md5 from 'md5';
import kali from 'kali';
import React, {Component} from 'react';
import DirectoryView from './components/DirectoryView.js';
import GalleryList from './components/GalleryList.js';
import ImgUploader from './components/ImgUploader.js';
import SlideForm from './components/SlideForm.js';
import VidThumbNail from './images/video.svg';
import NavBar from './components/NavBar.js';
import moment from 'moment';
import './App.css';

// Loader
import Loader from './components/loader.js';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;
class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeItem:           false,
			activeSlideshow:	  1,
			apiPrefix:            API_PREFIX,
			bucketName:           'ts-4.1-uploads',
			bucketRegion:         'us-east-1',
			directories:          {},
			directoryView:        false,
			errorMsg:             '',
			loading:              true,
			galleryData:          [],
			indentityPoolID:      'us-east-1:bb4a7a9c-f7ab-4196-9111-9ef948ad720e',
			noImgs:               false,
			originalBuildingData: '',
			propNum:              this.getQueryParam('propNum') ?
				this.getQueryParam('propNum') :
				'',
			s3Cli: new this.props.AWS.S3({
				apiVersion: '2006-03-01',
				params:     {
					Bucket: 'ts-4.1-uploads'
				}
			}),
			s3Data:     {},
			sessionKey: this.getQueryParam('session_key') ?
				decodeURIComponent(this.getQueryParam('session_key')) :
				'',
			uploadProgress: '',
			uploadView:     false,
			bodyScroll: true
		};

		this.addObject = this.addObject.bind(this);
		this.changeFormValue = this.changeFormValue.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.createPropertyDir = this.createPropertyDir.bind(this);
		this.deleteObject = this.deleteObject.bind(this);
		this.errCallback = this.errCallback.bind(this);
		this.getQueryParam = this.getQueryParam.bind(this);
		this.md5GetQueryParams = this.md5GetQueryParams.bind(this);
		this.md5PostXAuthHeaders = this.md5PostXAuthHeaders.bind(this);
		this.runFullSetup = this.runFullSetup.bind(this);
		this.showUploadForm = this.showUploadForm.bind(this);
		this.toggleActiveItem = this.toggleActiveItem.bind(this);
		this.toggleActiveSlideShow = this.toggleActiveSlideShow.bind(this);
		this.toggleSystemSelect = this.toggleSystemSelect.bind(this);
		this.updateObject = this.updateObject.bind(this);
		this.viewDirectory = this.viewDirectory.bind(this);
		this.viewGallery = this.viewGallery.bind(this);
	}

	componentDidMount() {
		this.runFullSetup();
	}

	errCallback(dataObj) {
		this.setState(dataObj);
	}

	async runFullSetup() {
		if (!this.state.propNum || this.state.propNum === '') {
			console.error('No Prop Number provided');
			return this.setState({errMsg: 'No PropNumber provided'});
		}
		if (!this.state.sessionKey || this.state.sessionKey === '') {
			console.error('No Session provided');
			return this.setState({errMsg: 'No session provided'});
		}
		this.setState({activeItem: false});
		let qps = this.md5GetQueryParams();
		let dataURL = `//${this.state.apiPrefix}/bronco/legacy/updateBuildingsByProp/${this.state.propNum}/?${qps}`;
		const globalErrHandler = this.errCallback;

		const bucketCheckOpts = {
			Bucket: this.state.bucketName
		};

		try {
			console.log('Awaiting...');
			await this.state.s3Cli.headBucket(bucketCheckOpts).promise();
			console.log('...Done');
		} catch (err) {
			// if 404, there isn't an associated bucket.
			if (err.statusCode === 404) {
				return this.createPropertyDir(this.runFullSetup);
			}

			let nextErr = err.message ? err.message : err;
			return this.setState({errMsg: nextErr});
		}

		new kali().get(dataURL, {
			success: (_kali, res, contents) => {
				console.log('In Success');
				console.log(res);
				console.log(contents);
				if (contents && contents.data) {
					console.log('In Has contents.data');
					let frozenContents = JSON.stringify(contents.data);
					let buildingNames = {};
					contents.data.forEach((bldgData, i) => {
						// Excluding directory 0 since it's being used for Wayfinding
						if (bldgData.d_num  && bldgData.d_num !== '0' && bldgData.display_name) {
							let id = bldgData.bldg_id;
							buildingNames[bldgData.d_num] = {
								directory:   bldgData.d_num,
								displayName: bldgData.display_name,
								key:         `${i}-directory-entry`,
								selected:    false // ???
							};
						}
					});

					console.log('About to list objects');
					this.state.s3Cli.listObjects(
						{
							Bucket:    this.state.bucketName,
							Delimiter: '/',
							Prefix:    `${this.state.propNum}/`
						},
						(err, data) => {
							console.log('In list objects CB');
							if (err) {
								console.error(err);
								let nextErr = err.message ? err.message : err;
								return globalErrHandler({
									errorMsg:             nextErr,
									directories:          buildingNames,
									originalBuildingData: frozenContents
								});
							}
							if (data && data.Contents) {
								console.log('Contents found');
								if (data.Contents.length === 0) {
									return this.setState({noImg: true, galleryData: [], loading: false});
								}
								this.setState({noImg: false});

								let galleryDataKeys = [];
								let galleryDataJSONKeys = [];

								data.Contents.forEach((obj) => {
									let keyArray = obj.Key.split('/');
									if (keyArray[1] === '') {
										return;
									}

									if (~obj.Key.indexOf('.json')) {
										galleryDataJSONKeys.push(obj.Key);
									} else {
										galleryDataKeys.push(obj.Key);
									}
								});

								this.setState({galleryData: [], loading: true});
								console.log('Getting Tag Data');

								let imgObjs = [];

								galleryDataKeys.forEach((key, i) => {
									let params = {
										Bucket: this.state.bucketName,
										Key:    key
									};

									let jsonKey = `${key}.json`;
									if (~galleryDataJSONKeys.indexOf(jsonKey)) {
										params.Key = jsonKey;
										this.state.s3Cli.getObject(params, (objErr, objData) => {
											let s = objData.Body.toString();
											try {
												let imgObj = JSON.parse(s);

												imgObj.assetURL = `https://s3.amazonaws.com/${this.state.bucketName}/${key}`;
												imgObj.imgURL = `https://s3.amazonaws.com/${this.state.bucketName}/${encodeURIComponent(key)}`;

												let k = `img-${i}`;
												imgObj.imgElm = (
													<img key={k} src={imgObj.imgURL} alt="" />
												);

												if (
													!imgObj.kind ||
													imgObj.kind.split('/')[0] === 'video'
												) {
													let k = `img-${i}`;
													imgObj.imgElm = (
														<img key={k} src={VidThumbNail} alt="" />
													);
												}

												let o = JSON.parse(s);
												o.systems = o.systems.split('-') || [];

												imgObjs.push(o);
												imgObj.systems = o.systems;

												let nextGalleryData = this.state.galleryData;
												imgObj.index = nextGalleryData.length;
												nextGalleryData.push(imgObj);
												this.setState({
													galleryData: nextGalleryData,
													loading:     false
												});
												console.log('Updating Gallery Data');
											} catch (parseErr) {
												console.warn(parseErr);
												imgObjs.push({});
											}
										});

										return;
									}

									this.state.s3Cli.getObjectTagging(
										params,
										(tagErr, tagData) => {
											if (tagErr) {
												console.error('Failed to get object tagging:');
												console.error(tagErr);
												let nextErr = tagErr.message ? tagErr.message : tagErr;
												return globalErrHandler({errMsg: nextErr});
											}

											if (!tagData || !tagData.TagSet) {
												console.error('s3 Data does not have a TagSet:');
												console.error(tagData);
												return globalErrHandler({
													errMsg: 's3 data does not have a TagSet'
												});
											}

											let imgObj = {};
											let tagset = tagData.TagSet;
											tagset.forEach((tagObj) => {
												imgObj[tagObj.Key] = tagObj.Value;
											});

											imgObj.assetURL = `https://s3.amazonaws.com/${this.state.bucketName}/${key}`;
											imgObj.imgURL = `https://s3.amazonaws.com/${this.state.bucketName}/${encodeURIComponent(key)}`;

											let k = `img-${i}`;
											imgObj.imgElm = (
												<img key={k} src={imgObj.imgURL} alt="" />
											);

											if (
												!imgObj.kind ||
												imgObj.kind.split('/')[0] === 'video'
											) {
												let k = `img-${i}`;
												imgObj.imgElm = (
													<img key={k} src={VidThumbNail} alt="" />
												);
											}

											if (imgObj.systems) {
												imgObj.systems = imgObj.systems.split('-');
											}

											let nextGalleryData = this.state.galleryData;
											imgObj.index = nextGalleryData.length;
											nextGalleryData.push(imgObj);
											this.setState({
												galleryData: nextGalleryData,
												loading:     false
											});
											console.log('Updating Gallery Data');

											imgObjs.push(imgObj);
										}
									);
								});
							}
						}
					);

					return this.setState({
						directories:          buildingNames,
						originalBuildingData: frozenContents
					});
				}
			},

			failure: (_kali, err) => {
				console.error('ERR IN Building API Call:');
				console.error(err);
				return this.setState({errorMsg: err});
			}
		});
	}

	createPropertyDir(callback) {
		this.state.s3Cli.headObject({Key: this.state.propNum.trim()}, function (
			err,
			data
		) {
			if (!err) {
				console.debug('Propery Dir already exists.');
				if (typeof callback === 'function') {
					return callback();
				}

				return;
			}

			this.state.s3Cli.putObject({Key: this.state.propNum + '/'}, function (
				err,
				data
			) {
				console.debug('Successfully created album.');
				if (typeof callback === 'function') {
					return callback();
				}
			});
		});
	}

	addObject(file, fileForm, systems, slideshow, callback) {
		let fileName = file.name;
		fileForm.systems = systems;
		fileForm.slideshow = [slideshow];

		let objectKey = (encodeURIComponent(this.state.propNum) + '/' + fileName);
		objectKey = objectKey.replace(new RegExp(/\s/, 'g'), '');
		const globalErrHandler = this.errCallback;

		this.state.s3Cli
			.upload(
				{
					Key:  objectKey,
					Body: file,
					ACL:  'public-read'
				},
				{
					partSize:  5 * 1024 * 1024,
					queueSize: 5
				},
				function (err, data) {
					if (err) {
						console.error(err, data);
						alert('There was an error uploading your photo: ', err.message);
						let nextErr = err.message ? err.message : err;
						return globalErrHandler({errMsg: nextErr});
					}

					callback(objectKey, file, fileForm);
				}
			)
			.on('httpUploadProgress', (evt) => {
				console.log('Progress:', evt.loaded, '/', evt.total);
				let numerator = parseInt(evt.loaded, 10);
				let denominator = parseInt(evt.total, 10);
				let progress = Math.floor((numerator / denominator) * 100);
				let uploadString = `Upload ${progress}% complete...`;
				this.setState({uploadProgress: uploadString});
			});
	}

	updateObject(objectKey, file, fileForm, callback) {
		let order = fileForm.order ? fileForm.order : '1';
		let duration = fileForm.duration ? fileForm.duration : '7';
		let description = fileForm.description ? fileForm.description : '_EMPTY';
		description = description.trim().replace(new RegExp(/[\\@#$%^&*;|<>"'()\[\]]/, 'g'), '');
		let startDate = fileForm.start_date ? moment(fileForm.start_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD_HH:mm') : '';
		let stopDate = fileForm.stop_date ? moment(fileForm.stop_date, 'YYYY-MM-DD_HH:mm').format('YYYY-MM-DD_HH:mm') : '';

		let systemsStr = fileForm.systems.join('-');
		systemsStr = systemsStr.replace(new RegExp('--', 'g'), '-');
		if (systemsStr[0] === '-') {
			systemsStr = systemsStr.substr(1);
		}
		let systems = (systemsStr && systemsStr.length && systemsStr.length > 0) ? systemsStr : '';

		if (startDate === 'Invalid date') {
			startDate = moment(fileForm.start_date).format('YYYY-MM-DD_HH:mm') || '';
		}

		if (startDate === 'Invalid date' || startDate === '') {
			startDate = null;
		}

		if (stopDate === 'Invalid date') {
			stopDate = moment(fileForm.stop_date).format('YYYY-MM-DD_HH:mm') || '';
		}

		if (stopDate === 'Invalid date' || stopDate === '') {
			stopDate = null;
		}

		// NOTE: systems needs to be string '-' if none selected
		if (!systemsStr || systemsStr.length === 0) {
			systems = '-';
		}

		// NOTE: this is required for videos to play to their own duration.
		if (~file.type.indexOf('video')) {
			duration = '0';
		}

		this.state.s3Cli.putObject({
			Bucket: this.state.bucketName,
			Key:    objectKey + '.json',
			Body:   JSON.stringify({
				kind:         file.type,
				order:        order,
				duration:     duration,
				description:  description,
				slideshow:	  [this.state.activeSlideshow],
				'start_date': startDate,
				'stop_date':  stopDate,
				systems:      systems
			}, null, 2),
			ContentType: 'application/json',
			ACL:         'public-read'
		}, (err, data) => {
			console.log(JSON.stringify(err) + ' ' + JSON.stringify(data));
			return callback();
		});

		console.log(fileForm);
		this.setState({uploadProgress: ''});
		document.body.classList.remove('no-scroll');
	}

	deleteObject(objectKey, callback) {
		console.log(objectKey);
		let deleteObjectRequest = this.state.s3Cli.deleteObject({Key: objectKey});
		const globalErrHandler = this.errCallback;

		deleteObjectRequest
			.on('success', function (res) {
				console.log('Successfully Deleted!');
			})
			.on('error', function (res, err) {
				let nextErr = err.message ? err.message : err;
				return globalErrHandler({errMsg: nextErr});
			})
			.on('complete', function (res) {
				console.debug('Successfully deleted photo.');
				if (typeof callback === 'function') {
					return callback();
				}
			})
			.send();
	}

	toggleSystemSelect(index, targetSys) {
		let galleryData = this.state.galleryData;
		let targetIndex = galleryData[index].systems.indexOf(targetSys);
		if (targetIndex !== -1) {
			galleryData[index].systems.splice(targetIndex, 1);
			return this.setState({galleryData: galleryData});
		}

		galleryData[index].systems.push(targetSys);
		return this.setState({galleryData: galleryData});
	}

	toggleActiveItem(id) {
		this.setState({activeItem: id});
		document.body.classList.add('no-scroll');
	}

	toggleActiveSlideShow(id) {
		this.setState({activeSlideshow: id});
	}

	changeFormValue(index, key, value) {
		let galleryData = this.state.galleryData;
		galleryData[index][key] = value;
		this.setState({galleryData: galleryData});
	}

	getQueryParam(param) {
		let query = window.location.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			if (pair[0] === param) {
				return pair[1];
			}
		}
		return false;
	}

	md5GetQueryParams() {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		return `auth_id=${encodeURIComponent(
			this.state.sessionKey
		)}&auth_key=${sKey}&auth_ts=${ts}`;
	}

	md5PostXAuthHeaders() {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		let headers = {
			'X-Auth-ID':  this.state.sessionKey,
			'X-Auth-Key': sKey,
			'X-Auth-TS':  ts
		};
		return headers;
	}

	viewDirectory() {
		this.setState({directoryView: true, activeItem: false});
	}

	viewGallery() {
		this.setState({directoryView: false, activeItem: false});
	}
	closeModal() {
		this.setState({activeItem: false, uploadView: false});
		document.body.classList.remove('no-scroll');
	}
	showUploadForm() {
		this.setState({uploadView: true});
		document.body.classList.add('no-scroll');
	}

	

	render() {
			
		let galleryData = this.state.galleryData;
		let activeGalleryData = [];
		galleryData.forEach((entry) => {
			if (entry.slideshow === undefined) {
				entry.slideshow = [1];
			}

			entry.slideshow.forEach((value) => {
				if (value === this.state.activeSlideshow) {
					activeGalleryData.push(entry);
				}
			});
		});

		let galleryList = GalleryList({
			deleteObject:     this.deleteObject,
			runFullSetup:     this.runFullSetup,
			list:             activeGalleryData,
			toggleActiveItem: this.toggleActiveItem
		});
		
		let activeItemProps =
			this.state.activeItem !== false ?
				Object.assign({}, activeGalleryData[this.state.activeItem]) :
				{};
		activeItemProps.directoryList = this.state.directories;
		activeItemProps.toggleSystemSelect = this.toggleSystemSelect;
		activeItemProps.changeFormValue = this.changeFormValue;
		activeItemProps.runFullSetup = this.runFullSetup;
		activeItemProps.updateObject = this.updateObject;
		activeItemProps.deleteObject = this.deleteObject;
		activeItemProps.activeItemId = this.state.activeItem.id;
		activeItemProps.runFullSetup = this.runFullSetup;
		activeItemProps.closeModal = this.closeModal;
		activeItemProps.viewGallery = this.viewGallery;

		let navProps = {
			activeItem:     this.state.activeItem,
			directoryView:  this.state.directoryView,
			viewDirectory:  this.viewDirectory,
			viewGallery:    this.viewGallery,
			showUploadForm: this.showUploadForm,
			toggleActiveSlideShow: this.toggleActiveSlideShow
		};

		let dirProps = this.state.directoryView ?
			{
				activeSlideshow:  this.state.activeSlideshow,
				directoryList:    this.state.directories,
				galleryData:      this.state.galleryData,
				toggleActiveItem: this.toggleActiveItem
			} :
			{
				directoryList: {},
				galleryData:   []
			};
		return (
			<div >
				{NavBar(navProps)}
				{this.state.loading ? <p>Loading assets...</p> : ''}
				{this.state.noImg ? <p>No Images Found</p> : ''}
				{this.state.errorMsg ? (
					<p>Error encountered: {this.state.errorMsg.message}</p>
				) : (
					''
				)}
				{this.state.uploadProgress ? <Loader /> : ''}
				{this.state.uploadView ? (
					<ImgUploader
						callback={(objectKey, file, fileForm) => {
							this.updateObject(objectKey, file, fileForm, this.runFullSetup);
						}}
						addObject={this.addObject}
						directoryList={this.state.directories}
						closeModal={this.closeModal}
						activeSlideshow={this.state.activeSlideshow}
					/>
				) : (
					''
				)}
				{!this.state.directoryView ? galleryList : ''}
				{this.state.activeItem !== false ? (
					<SlideForm {...activeItemProps} />
				) : (
					''
				)}
				{this.state.directoryView ? <DirectoryView {...dirProps} /> : ''}
			</div>
		);
	}
}

export default App;
